<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="c-input-small"
        v-model="dateFormatted"
        :label="label"
        :placeholder="placeholder"
        :disabled="disabled"
        hide-details
        outlined
        dense
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      :locale="$i18n.locale"
      :min="minDate"
      :disabled="disabled"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import { debounce } from "lodash/function";

export default {
  name: "DatePicker",
  props: {
    idItem: {
      type: [Number, String],
      default: () => null,
    },
    name: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    minDate: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    defaultValue: {
      type: [Array, String],
      default: () => null,
    },
  },
  data: () => ({
    date: null,
    dateFormatted: null,
    menu: false,
  }),
  watch: {
    date() {
      this.dateFormatted = moment(this.date).format("DD/MM/YYYY");
      this.onDateChange();
    },
  },
  methods: {
    onDateChange: debounce(function () {
      this.$emit("onChange", {
        id: this.idItem,
        name: this.name,
        value: this.date,
      });
    }, 100),
  },
  mounted() {
    if (this.defaultValue) {
      this.date = this.defaultValue;
    }
    this.onDateChange();
  },
};
</script>

<style scoped></style>
